import { Tabs } from "antd";
import React, { ReactElement } from "react";
import EditSiteForm from "components/common/EditSiteForm";
import UploadKMLFile from "components/UploadKMLFile/UploadKMLFile";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { FEATURE_FLAGS } from "utils/appFlags";
import { useSite } from "contexts/SiteDetailsContext";

const defaultActiveTabKey = "1";

const ManageFacility = ({
  availableTerritories,
}: {
  availableTerritories: AvailableTerritories;
}): ReactElement => {
  const { hasFlag } = useFeatureFlags();

  const { site } = useSite();
  const showPenUpload = hasFlag(FEATURE_FLAGS.PEN_UPLOAD);

  const items = [
    {
      label: "General",
      key: "1",
      children: (
        <EditSiteForm site={site} availableTerritories={availableTerritories} />
      ),
    },
    { label: "Geotracking", key: "2", children: <UploadKMLFile /> },
  ];

  return (
    <Tabs
      type="card"
      destroyInactiveTabPane={true}
      defaultActiveKey={defaultActiveTabKey}
      style={{ marginTop: "80px" }}
      items={showPenUpload ? items : [items[0]]}
    />
  );
};

export default ManageFacility;

import React, { Component } from "react";

import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  notification,
  Modal,
} from "antd";
import concat from "lodash/concat";

import axios from "axios";
import errorMessage from "utils/errorMessage";
import PasswordChange from "components/common/PasswordChange";

const Option = Select.Option;
const FormItem = Form.Item;
const confirm = Modal.confirm;

const BUTTON_GROUP_LINE_HEIGHT = "40px";

class EditSiteForm extends Component {
  state = {
    availableTerritories: this.props.availableTerritories,
    tempTerritory: null,
    site: this.props.site,
    activeStatus: this.props.site.is_active.toString(),
    changePassword: false,
  };

  formRef = React.createRef();

  handleSubmit = (e) => {
    e.preventDefault();
    const { site } = this.state;
    const form = this.formRef.current;

    form.validateFields().then((values) => {
      axios.put(`/cms/sites/${site.id}`, this.getSubmissionData(values)).then(
        () => {
          localStorage.setItem(
            "success-notification",
            "Facility updated successfully"
          );
          window.location = `/cms/facilities/${site.id}`;
        },
        (error) => errorMessage(error)
      );
    });
  };

  getSubmissionData = (values) => ({
    name: values.name,
    is_active: values.is_active === "true",
    notes: values.notes,
    territory: values.territory,
    password: values.password,
    password_confirmation: values.password_confirmation,
  });

  filterTerritories = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  handleTerritoriesChange = (value) => {
    const oldTerritories = this.state.availableTerritories.map((t) =>
      t.name.toLowerCase()
    );
    const territoryFound = oldTerritories.includes(value.toLowerCase());
    const tempTerritory =
      value && value !== "" && !territoryFound
        ? { key: "temp", name: value }
        : null;
    this.setState({ tempTerritory });
  };

  handleStatusChange = (value) => {
    const { activeStatus, site } = this.state;
    const form = this.formRef.current;
    if (activeStatus === "true") {
      confirm({
        title: <span>DEACTIVATE SITE</span>,
        content: `Are you sure you want to deactivate ${site.name}? This will deactivate all module access for this site.`,
        onOk: () => this.toggleActiveStatusSelected(value),
        okText: "Deactivate",
        okType: "danger",
        onCancel: () => form.setFieldsValue({ is_active: "true" }),
        cancelType: "default",
        className: "no-icon-modal",
        width: 530,
      });
    } else {
      this.toggleActiveStatusSelected(value);
    }
  };

  toggleActiveStatusSelected = (value) => {
    this.setState({ activeStatus: value });
  };

  toggleChangePassword = (isChecked) => {
    this.setState({ changePassword: isChecked });
  };

  resetTempTerritory = () => {
    this.setState({ tempTerritory: null });
  };

  confirmSiteClear = () =>
    this.confirmationModal(
      "CLEAR SITE DATA",
      `Are you sure you want to clear the data for ${this.state.site.name}? This site will need to sync to retrieve data again.`,
      this.clearSiteData,
      "Clear Data"
    );

  confirmSiteDelete = () =>
    this.confirmationModal(
      "DELETE SITE",
      `Are you sure you want to delete ${this.state.site.name}? This will also remove all site administrators and site data.`,
      this.deleteSite,
      "Delete"
    );

  confirmationModal = (title, content, onOk, okText) =>
    confirm({
      title: <span>{title}</span>,
      content: content,
      onOk: onOk,
      okText: okText,
      okButtonProps: { danger: true },
      cancelType: "default",
      className: "no-icon-modal",
      width: 530,
      icon: null,
    });

  clearSiteData = () => {
    const promise = axios.put(`/cms/sites/${this.state.site.id}/clear-site`);

    promise.then(
      () => {
        notification.success({
          message: "Cleared Site",
          description: "The site has been successfully cleared",
        });
      },
      (error) => errorMessage(error)
    );
    return promise;
  };

  deleteSite = () => {
    const promise = axios.delete(`/cms/sites/${this.state.site.id}`);

    promise.then(
      () => {
        localStorage.setItem(
          "success-notification",
          "The site has been successfully deleted"
        );
        window.location = "/cms/facilities";
      },
      (error) => errorMessage(error)
    );
    return promise;
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.formRef.current;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you entered are inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.formRef.current;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"]);
    }
    callback();
  };

  render = () => {
    const { availableTerritories, tempTerritory, site, activeStatus } =
      this.state;
    const territoryList = tempTerritory
      ? concat(availableTerritories, [tempTerritory])
      : availableTerritories;

    return (
      <Form
        ref={this.formRef}
        requiredMark={false}
        className="ahi-form"
        style={{
          marginTop: "0px",
          borderRadius: "2px",
        }}
        initialValues={{
          name: site.name,
          territory: site.territory ? site.territory : "",
          notes: site.notes,
          is_active: site.is_active.toString(),
        }}
        layout="vertical"
      >
        <FormItem
          label={"FACILITY NAME"}
          colon={false}
          name="name"
          rules={[{ required: true, message: "Please input a facility name" }]}
        >
          <Input
            type="text"
            autoComplete="facility name"
            placeholder="Facility Name"
          />
        </FormItem>

        <FormItem
          label={"FACILITY TERRITORY"}
          style={{ width: "100%" }}
          colon={false}
          name="territory"
          rule={[{ required: false }]}
        >
          <Select
            showSearch={true}
            style={{ width: "100%" }}
            optionFilterProp="children"
            placeholder="Enter a Territory Name"
            filterOption={this.filterTerritories}
            onSearch={this.handleTerritoriesChange}
            onChange={this.resetTempTerritory}
            onClear={() => this.handleTerritoriesChange("")}
            allowClear={true}
          >
            {territoryList
              ? territoryList.map((territory) => (
                  <Option key={territory.name}>{territory.name}</Option>
                ))
              : ""}
          </Select>
        </FormItem>

        <FormItem
          label={"NOTES"}
          colon={false}
          name="notes"
          rule={[{ required: false }]}
        >
          <Input type="text" autoComplete="notes" placeholder="Notes" />
        </FormItem>

        <FormItem
          label={"Status"}
          colon={false}
          name="is_active"
          rule={[{ required: true }]}
        >
          <Select
            placeholder="Select a status"
            onChange={this.handleStatusChange}
            className={activeStatus === "true" ? "active" : "inactive"}
            style={{ width: "50%" }}
          >
            <Option value="true">Activated</Option>
            <Option value="false">Deactivated</Option>
          </Select>
        </FormItem>

        <FormItem
          label={"Facility Username"}
          colon={false}
          name="username_label"
          rule={[{ required: false }]}
        >
          <h4>{site.email}</h4>
        </FormItem>

        <PasswordChange
          changePassword={this.state.changePassword}
          toggleChangePassword={this.toggleChangePassword}
          validateToNextPassword={this.validateToNextPassword}
          compareToFirstPassword={this.compareToFirstPassword}
        />

        <Divider />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <div>
            <Button
              danger
              onClick={this.confirmSiteClear}
              style={{ marginRight: "8px" }}
            >
              Clear Site Data
            </Button>
            <Button danger onClick={this.confirmSiteDelete}>
              Delete Site
            </Button>
          </div>

          <div>
            <Button
              type="default"
              className="form-button"
              style={{ marginRight: "8px" }}
              onClick={() => (window.location = `/cms/facilities/${site.id}`)}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={this.handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    );
  };
}

export default EditSiteForm;

import React, { Component } from "react";
import {
  CalendarOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { Table, Modal } from "antd";
import errorMessage from "utils/errorMessage";
import PhotoSlideshow from "components/global/PhotoSlideshow";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import DateSelectorModal from "components/DateSelectorModal/DateSelectorModal";
import "url-search-params-polyfill";
import { SiteService } from "services/SiteService";
import { hasBothDates } from "utils/dateUtils";

class DeadAnimalList extends Component {
  state = {
    currentPage: 1,
    total: 0,
    deadAnimals: [],
    sortType: "asc",
    sortField: "tag",
    loading: true,
    dateStart: null,
    dateEnd: null,
    syncFilterOptions: [
      { label: "Finished Successfully", value: "posted_at_site" },
      {
        label: "Posted At Site and Pending Final Processing",
        value: "posted_and_pending_deletion",
      },
      { label: "Not Posted", value: "not_received" },
      { label: "Deleted", value: "deleted" },
      { label: "Failed", value: "failed_at_site" },
    ],
    syncFilter: null,
  };

  componentDidMount = () => {
    this.getTheData();
  };

  getTheData = (page = 1) => {
    SiteService.getDeadAnimalsList({
      page,
      sortBy: this.state.sortField,
      order: this.state.sortType,
      site: this.props.site.id,
      siteAdminId: this.props.siteAdminId,
      filterStatusBy: this.state.syncFilter ?? undefined,
      dateStart: hasBothDates(this.state.dateStart, this.state.dateEnd)
        ? this.state.dateStart
        : undefined,
      dateEnd: hasBothDates(this.state.dateStart, this.state.dateEnd)
        ? this.state.dateEnd
        : undefined,
    }).then(
      (response) => {
        const deadAnimals = response.data.data;
        const currentPage = response.data.current_page;
        const total = response.data.total;

        this.setState({ deadAnimals, currentPage, total, loading: false });
      },
      (error) => {
        errorMessage(error);
        this.setState({ loading: false });
      }
    );
  };

  toggleSortType = () => {
    this.setState(
      {
        sortType: this.state.sortType === "asc" ? "desc" : "asc",
        loading: true,
      },
      this.getTheData
    );
  };

  setSyncFilter = (filter, confirm) => {
    this.setState({ syncFilter: filter, loading: true }, this.getTheData);
    confirm();
  };

  showModal = (visible) => this.setState({ visible });

  updateDate = (dateStart, dateEnd) => {
    this.setState(
      {
        dateStart:
          dateStart && dateEnd ? dateStart.format("YYYY-MM-DD") : undefined,
        dateEnd:
          dateEnd && dateStart ? dateEnd.format("YYYY-MM-DD") : undefined,
        loading: true,
      },
      this.getTheData
    );
  };

  displaySlideshowModal = (photos) => {
    Modal.info({
      title: "Photo Gallery",
      content: <PhotoSlideshow photos={photos} />,
      className: "photo-gallery-modal",
      width: 600,
      maskClosable: true,
    });
  };

  render = () => {
    const columns = [
      {
        title: (
          <span
            className={`order-sorter ${this.state.sortType || ""}`}
            onClick={this.toggleSortType}
          >
            Tag{" "}
            <div className="icon-container">
              <CaretUpOutlined /> <CaretDownOutlined />
            </div>
          </span>
        ),
        dataIndex: "tag",
      },
      {
        title: (
          <div>
            Date
            <div
              onClick={() => this.setState({ visible: true })}
              className="icon-calendar"
            >
              <CalendarOutlined
                style={{
                  color:
                    this.state.dateStart && this.state.dateEnd
                      ? "#00C9B0"
                      : "inherit",
                }}
              />
            </div>
          </div>
        ),
        dataIndex: "death_date",
      },
      {
        title: "Death Pen",
        dataIndex: "death_pen",
        render: (deathPen) => (deathPen ? <span>Pen {deathPen}</span> : ""),
      },
      {
        title: "Death Location",
        dataIndex: "death_location",
      },
      {
        title: "Death Code",
        dataIndex: "death_code",
      },
      {
        title: "Status",
        dataIndex: "status",
        filterIcon: (
          <FilterOutlined
            style={{
              color: this.state.syncFilter ? "#108ee9" : "#aaa",
            }}
          />
        ),
        filterDropdown: ({ confirm }) => (
          <FilterDropdown
            onSetSelectedFilter={(filter) => {
              this.setSyncFilter(filter, confirm);
            }}
            selectedFilter={this.state.syncFilter}
            options={this.state.syncFilterOptions}
          />
        ),
      },
      {
        title: "Photo",
        dataIndex: "photos",
        render: (photos) =>
          photos && photos.length > 0 ? (
            <span
              onClick={() => this.displaySlideshowModal(photos)}
              className="table-modal-button-style"
            >
              View Slideshow
            </span>
          ) : (
            ""
          ),
      },
    ];

    const pagination = {
      onChange: this.getTheData,
      current: this.state.currentPage,
      total: this.state.total,
      pageSize: 12,
      hideOnSinglePage: true,
      showSizeChanger: false,
    };

    return (
      <>
        <Table
          columns={columns}
          dataSource={this.state.deadAnimals}
          loading={this.state.loading}
          className={"custom-table"}
          rowKey={"id"}
          pagination={pagination}
        />
        {this.state.visible && (
          <DateSelectorModal
            submit={this.updateDate}
            modalVisible={this.state.visible}
            showModal={this.showModal}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
          />
        )}
      </>
    );
  };
}

export default DeadAnimalList;

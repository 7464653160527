import { Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import React from "react";

const PenCoordinateCell = ({
  coordinates,
}: {
  coordinates: Coordinate[];
}): React.ReactElement => (
  <>
    {coordinates.map((coordinate, index) => (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ marginRight: "10px", color: "rgba(0, 0, 0, 0.4)" }}>
          {index + 1}
        </div>
        <div>
          {coordinate.latitude}, {coordinate.longitude}
        </div>
      </div>
    ))}
  </>
);

const columns: ColumnsType<PenAndCoordinateElement> = [
  {
    title: "Name",
    dataIndex: "pen_name",
    width: 93,
  },
  {
    title: "Pen Coordinates",
    dataIndex: "pen_data",
    render: (value: Array<PenData>) => {
      const penData = value.find((v) => v.type === "PEN");
      if (value.length === 0 || penData === undefined) {
        return <div>No coordinates</div>;
      }
      return <PenCoordinateCell coordinates={penData.coordinates} />;
    },
  },
  {
    title: "Bunk Coordinates",
    dataIndex: "pen_data",
    render: (value: Array<PenData>) => {
      const penData = value.find((v) => v.type === "BUNK");
      if (value.length === 0 || penData === undefined) {
        return <div>No coordinates</div>;
      }
      return <PenCoordinateCell coordinates={penData.coordinates} />;
    },
  },
];

const PenCoordinatesTable = ({
  tableData,
  loading = false,
}: {
  tableData: PenAndCoordinateElement[];
  loading?: boolean;
}): React.ReactElement => (
  <div className="pen-coordinate-table-container">
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      sticky={true}
      className={"upload-pen-coordinate-table"}
      rowKey={(record) => record.pen_name}
      data-testid={"pen-coordinates-list-table"}
      loading={loading}
    />
  </div>
);

export default PenCoordinatesTable;
